<template lang="pug">
  div.d-flex.flex-column
      HeaderGrid(:name="name" :uid="uid" :dateRanger="dateRanger" userFilter="myInterventionFilters" :filterCollaborators="filterCollaborators"  :dateRangeDefault="dateRangeDefault" :tvaModeButton="tvaModeButton" :addButton="addButton" :addButton2="addButton2" :addButton2Icon="addButton2Icon" :searchButton="searchButton" :tagguerButton="tagguerButton" :selectedRows="selectedRows" @setDates="setDates" @setCollaborators="setCollaborators" @onFilterTextBoxChanged="onFilterTextBoxChanged" @selectParamerterAction="setSelectParamerterAction" @clickAction="clickAction" :searchPlaceholder="searchPlaceholder" :groupActionButton="groupActionButton" :groupActionButtonLabel="groupActionButtonLabel" :groupActionList="groupActionList" @refreshRow="refreshRow" @clickActionGroup="clickActionGroup" :indexSearch="indexSearch" :homepageMode="homepageMode" :homepage="homepage")
      StatCards
          div.cursor-pointer.pl-1.col-card-list-stats(@click="changeTab('all')")
              .card-list-stats(:class="tabActive == 'all' ? 'current' : ''")
                  .card-list-stats-title
                      div
                          span.card-list-stats-title-badge.material-icons.text-primary.mr-50 list
                          span.card-list-stats-title-text TOUT
                      .card-list-stats-title-badge.bg-primary.text-white
                        span {{ getCountDataByStatus("all") }}
                  .card-sum
                      | {{ getSumDataByStatus("all") }} 
                      span Heures
          div.cursor-pointer.pl-1.col-card-list-stats(v-for="(status, index) of statusList" :key="index" @click="changeTab(status.value)")
              .card-list-stats(:class="[tabActive == status.value ? 'current' : '', 'border-'+status.class]")
                  .card-list-stats-title
                      div
                          span.card-list-stats-title-badge.material-icons.mr-25(:class="'text-'+status.class") {{ status.icon }}
                          span.card-list-stats-title-text {{ status.label }}
                      .card-list-stats-title-badge.text-white(:class="'bg-'+status.class")
                        span {{ getCountDataByStatus(status.value) }}
                  .card-sum
                      | {{ getSumDataByStatus(status.value) }} 
                      span Heures
      .d-flex.w-100.h-100(style="overflow: hidden;")
        .text-center.flex-center.w-100(v-if='isLoadingInterventionsByCollaboratorList || !headerLoaded || !dataLoaded')
              .loading-bg-inner
                  .loader
                      .outer
                      .middle
                      .inner
              .mt-5
                  br
                  br
                  br
                  | Chargement de la liste de mes interventions...
        // sync-grid#overviewgrid(ref="overviewgrid" v-else :uid="uid" :gridData="dataFiltered" :name="name" :headerData="headerData" :selectionSettings="selectionSettings" :allowGrouping="allowGrouping" @setSelectedRows="setSelectedRows" :tagguerButton="tagguerButton" :allowKeyboard="allowKeyboard" @actionBegin="actionBegin" @deleteButtonClicked="deleteButtonClicked" @rowClicked="onRowClicked")
        sync-grid#overviewgrid(ref="overviewgrid" v-else :uid="uid" :gridData="dataFiltered" :name="name" :headerData="headerData" :selectionSettings="selectionSettings" :editSettings="editSettings" :allowGrouping="allowGrouping" @setSelectedRows="setSelectedRows" :tagguerButton="tagguerButton" :allowKeyboard="allowKeyboard" @actionBegin="actionBegin" @rowClicked="onRowClicked" @deleteButtonClicked="deleteButtonClicked")
      ejs-sidebar.default-sidebar.items-no-padding(v-show="isOpenSidebarIntervention" ref="sidebarIntervention" :type="type" :position="position" :showBackdrop="showBackdrop" :enablePersistence="enablePersistence" :closeOnDocumentClick="closeOnDocumentClick" :isOpen="isOpenSidebarIntervention")
        sidebar-intervention-content(ref='sidebarInterventionContent' @refreshGrid="refreshGrid" @closeSidebar="closeSidebar")
  </template>

<script>
import { mapGetters, mapActions } from "vuex";
import { interventionStatus } from "@/types/api-orisis/enums/enums";

import { formatNumber } from "@/types/api-orisis/library/FormatOperations.ts";
import { formatCurrency } from "@/types/api-orisis/library/FormatOperations.ts";
import { setColumnsByObject } from "@/types/api-orisis/library/ObjectOperations.ts";
import SidebarInterventionContent from "@/components/intervention/SidebarInterventionContent.vue";
import { SidebarComponent } from "@syncfusion/ej2-vue-navigations";

var dayjs = require("dayjs");
var quarterOfYear = require("dayjs/plugin/quarterOfYear");
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
var advanced = require("dayjs/plugin/advancedFormat");
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Europe/Paris");
dayjs.extend(advanced);
dayjs.extend(quarterOfYear);

import SyncGrid from "@/components/global/grid/Grid.vue";
import StatCards from "@/components/global/grid/StatCards.vue";
import HeaderGrid from "@/components/global/grid/HeaderGrid.vue";
import { createElement } from "@syncfusion/ej2-base";
import { DropDownList } from "@syncfusion/ej2-dropdowns";
import { DataManager } from "@syncfusion/ej2-data";
let dropInstanceCollabFilter;
let dropInstanceCompanyFilter;
let dropInstanceAffairFilter;

export default {
  components: {
    SyncGrid,
    HeaderGrid,
    StatCards,
    SidebarInterventionContent,
    "ejs-sidebar": SidebarComponent,
  },
  data() {
    return {
      homepageMode: true,
      homepage: false,
      dateRangeDefault: [
        new Date(
          dayjs().subtract(3, "month").startOf("month").format("YYYY-MM-DD")
        ).toISOString(),
        new Date(dayjs().endOf("month").format("YYYY-MM-DD")).toISOString(),
      ],
      indexSearch: 0,
      closeOnDocumentClick: true,
      showBackdrop: true,
      enablePersistence: false,
      isOpenSidebarIntervention: false,
      position: "Right",
      type: "Push",
      name: "interventions",
      uid: "myInterventions",
      statusList: interventionStatus,
      headerData: [],
      headerLoaded: false,
      tabActive: "all",
      selectedRows: [],
      collaboratorIds: [],
      dataLoaded: false,
      dateRanger: true,
      filterCollaborators: false,
      tvaModeButton: false,
      addButton: true,
      addButton2: false,
      addButton2Icon: "e-icons e-people",
      searchButton: true,
      tagguerButton: false,
      groupActionButton: true,
      groupActionButtonLabel: "Changer le statut",
      searchPlaceholder: "Rechercher une intervention",
      allowKeyboard: false,
      allowGrouping: true,
      selectionSettings: {
        type: "Multiple",
        enableSimpleMultiRowSelection: false,
      },
      editSettings: {
        allowEditing: true,
        allowAdding: true,
        allowDeleting: true,
        mode: "Dialog",
        titleAdd: "Nouvelle intervention",
        titleEdit: "Modifier l'intervention",
        titleField: "date",
      },
      newReportType: "single",
    };
  },
  computed: {
    ...mapGetters([
      "interventionsListByCollaboratorId",
      "isLoadingInterventionsByCollaboratorList",
      "collaboratorsList",
      "affairsList",
      "userParametersList",
      "companiesList",
      "userCollaboratorId",
    ]),
    groupActionList() {
      let groupAction = [];
      if (this.selectedRows.find((elem) => elem.status <= 0)) {
        groupAction.push({
          text:
            "Passer en réalisation (" +
            this.selectedRows.filter((elem) => elem.status <= 0).length +
            ")",
          iconCss: "e-icons e-send",
          css: "e-warning",
          value: 1,
        });
      }
      if (this.selectedRows.find((elem) => elem.status <= 1)) {
        groupAction.push({
          text:
            "Valider (" +
            this.selectedRows.filter((elem) => elem.status <= 1).length +
            ")",
          iconCss: "e-icons e-check",
          css: "e-success",
          value: 2,
        });
      }

      return groupAction;
    },
    dataOrigine() {
      return this.interventionsListByCollaboratorId;
    },
    dataFiltered() {
      if (this.statusList) {
        if (this.tabActive == "all") {
          return this.interventionsListByCollaboratorId;
        } else {
          let statusInfos = this.statusList.find(
            (elem) => elem.value == this.tabActive
          );
          return this.interventionsListByCollaboratorId.filter((elem) =>
            statusInfos.query
              ? statusInfos.query.includes(elem.status)
              : elem.status === this.tabActive
          );
        }
      } else {
        return [];
      }
    },
  },
  async created() {
    Promise.all([
      this.getCollaborators({}),
      this.getAffairs({}),
      this.getCompanies({}),
    ]).then(async (res) => {
      this.dataLoaded = true;
    });
  },
  async activated() {
    let filter = this.userParametersList.find(
      (el) => el.key == "myInterventionFilters"
    );
    if (filter) {
      if (JSON.parse(filter.value).dateRange) {
        this.setDates(JSON.parse(filter.value).dateRange);
      } else {
        this.setDates({
          startDate: dayjs().startOf("year").format("YYYY-MM-DD"),
          endDate: dayjs().endOf("year").format("YYYY-MM-DD"),
        });
      }
    } else {
      await this.setDates({
        startDate: dayjs()
          .subtract(3, "month")
          .startOf("month")
          .format("YYYY-MM-DD"),
        endDate: dayjs().endOf("month").format("YYYY-MM-DD"),
      });
    }
  },
  methods: {
    ...mapActions([
      "getInterventionsByCollaboratorId",
      "updateIntervention",
      "getCollaborators",
      "getAffairs",
      "deleteInterventions",
      "getCompanies",
    ]),
    formatNumber,
    formatCurrency,
    setColumnsByObject,

    setHeaderData() {
      this.headerData = [
        {
          type: "checkbox",
          headerText: null,
          width: 30,
          maxWidth: 30,
          visible: true,
        },
        {
          type: "string",
          field: "status",
          headerText: "Statut",
          width: 110,
          maxWidth: 110,
          template: "statusTemplate",
          nature: "interventionStatus",
          allowFiltering: true,
          allowSorting: true,
          showInColumnChooser: true,
          visible: true,
          filterTemplate: "statusFilterTemplate",
        },
        {
          type: "date",
          format: "formatDateOptions",
          field: "date",
          headerText: "Date",
          width: 250,
          minWidth: 160,
          template: "dateTemplate",
          allowFiltering: true,
          allowSorting: true,
          allowEditing: false,
          showInColumnChooser: true,
          visible: true,
          direction: "Descending",
        },
        {
          type: "string",
          field: "collaboratorIds",
          headerText: "Collaborateur(s)",
          width: "auto",
          minWidth: 200,
          allowFiltering: true,
          allowSorting: true,
          allowEditing: true,
          showInColumnChooser: true,
          visible: true,
          valueAccessor: "dropdown",
          template: "collaboratorsTemplate",
          filter: {
            ui: {
              create: (args) => {
                let flValInput = createElement("input", {
                  className: "flm-input",
                });
                args.target.appendChild(flValInput);
                dropInstanceCollabFilter = new DropDownList({
                  dataSource: new DataManager(args.column.edit.dataSource),
                  fields: { text: "displayLabel", value: "id" },
                  placeholder: "Sélectionnez une valeur",
                  popupHeight: "200px",
                });
                dropInstanceCollabFilter.appendTo(flValInput);
              },
              write: (args) => {
                dropInstanceCollabFilter.value = args.filteredValue;
              },
              read: (args) => {
                args.fltrObj.filterByColumn(
                  args.column.field,
                  args.operator,
                  dropInstanceCollabFilter.value
                );
              },
            },
          },
          edit: {
            source: this.collaboratorsList,
            fields: { text: "displayLabel", value: "id" },
            type: "dropdownmultiple",
            name: "dropdowncollaborator",
          },
        },
        {
          type: "number",
          field: "companyId",
          dataSource: this.companiesList,
          foreignKeyField: "id",
          foreignKeyValue: "name",
          editType: "dropdown",
          headerText: "Client",
          width: 200,
          minWidth: 200,
          allowFiltering: true,
          allowSorting: true,
          allowEditing: true,
          showInColumnChooser: true,
          visible: true,
          valueAccessor: "dropdown",
          template: "companyTemplate",
          filter: {
            ui: {
              create: (args) => {
                let flValInput = createElement("input", {
                  className: "flm-input",
                });
                args.target.appendChild(flValInput);
                dropInstanceCompanyFilter = new DropDownList({
                  dataSource: new DataManager(args.column.edit.dataSource),
                  fields: { text: "name", value: "id" },
                  placeholder: "Sélectionnez une valeur",
                  popupHeight: "200px",
                });
                dropInstanceCompanyFilter.appendTo(flValInput);
              },
              write: (args) => {
                dropInstanceCompanyFilter.value = args.filteredValue;
              },
              read: (args) => {
                args.fltrObj.filterByColumn(
                  args.column.field,
                  args.operator,
                  dropInstanceCompanyFilter.value
                );
              },
            },
          },
          edit: {
            source: this.companiesList,
            fields: { text: "name", value: "id" },
            type: "dropdowndependencies",
            name: "dropdowncompany",
            target: "dropdownaffair",
          },
        },
        {
          type: "number",
          field: "affairCodeId",
          dataSource: this.affairsList,
          foreignKeyField: "id",
          foreignKeyValue: "code",
          editType: "dropdown",
          headerText: "Code",
          width: 200,
          minWidth: 200,
          allowFiltering: true,
          allowSorting: true,
          allowEditing: true,
          showInColumnChooser: true,
          visible: true,
          template: "affairCodeTemplate",
          filterTemplate: "affairCodeFilterTemplate",
        },
        {
          type: "number",
          field: "affairId",
          dataSource: this.affairsList,
          editType: "dropdown",
          foreignKeyField: "id",
          foreignKeyValue: "displayLabel",
          headerText: "Affaire",
          width: 200,
          minWidth: 200,
          allowFiltering: true,
          allowSorting: true,
          allowEditing: true,
          showInColumnChooser: true,
          visible: true,
          valueAccessor: "dropdown",
          template: "affairTemplate",
          filter: {
            ui: {
              create: (args) => {
                let flValInput = createElement("input", {
                  className: "flm-input",
                });
                args.target.appendChild(flValInput);
                dropInstanceAffairFilter = new DropDownList({
                  dataSource: new DataManager(args.column.edit.dataSource),
                  fields: { text: "displayLabel", value: "id" },
                  placeholder: "Sélectionnez une valeur",
                  popupHeight: "200px",
                });
                dropInstanceAffairFilter.appendTo(flValInput);
              },
              write: (args) => {
                dropInstanceAffairFilter.value = args.filteredValue;
              },
              read: (args) => {
                args.fltrObj.filterByColumn(
                  args.column.field,
                  args.operator,
                  dropInstanceAffairFilter.value
                );
              },
            },
          },
          edit: {
            source: this.affairsList,
            fields: { text: "displayLabel", value: "id" },
            type: "dropdown",
            name: "dropdownaffair",
          },
        },
        {
          type: "number",
          field: "duration",
          headerText: "Durée",
          width: 150,
          format: "N2",
          maxWidth: 150,
          textAlign: "center",
          allowEditing: true,
          allowFiltering: true,
          allowSorting: true,
          showInColumnChooser: true,
          visible: true,
          showFooterSum: true,
          editType: "numericedit",
          edit: { params: { decimals: 2 } },
          allowAggregate: true,
        },
        {
          type: "string",
          field: "message",
          headerText: "Commentaire",
          filter: { type: "Menu", operator: "contains" },
          width: "auto",
          minWidth: 300,
          showInColumnChooser: true,
          visible: true,
          allowGrouping: false,
          clipMode: "EllipsisWithTooltip",
          template: "stripHtmlTemplate",
        },
      ];
    },
    async setDates(dates) {
      if (dates.startDate) {
        let dateRange = {
          startDate: new Date(dates.startDate),
          endDate: dates.endDate ? new Date(dates.endDate) : new Date(),
        };
        this.dateRangeDefault = [dateRange.startDate, dateRange.endDate];
        await this.getInterventionsByCollaboratorId({
          collaboratorId: this.userCollaboratorId,
          startDate: dateRange.startDate,
          endDate: dateRange.endDate,
        }).then(async (res) => {
          this.setHeaderData();
          if (res.length) {
            await this.setColumnsByObject(res[0], null, this);
            this.indexSearch++;
          }
          this.headerLoaded = true;
        });
      }
    },
    async changeTab(tab) {
      this.tabActive = tab;
    },
    async actionBegin(args) {
      if (args.rowData && args.rowData.status > 1) {
        args.cancel = true;
      } else if (
        args.requestType === "beginEdit" ||
        args.requestType === "add"
      ) {
        for (
          var i = 0;
          i < this.$refs.overviewgrid.$refs.overviewgrid.getColumns().length;
          i++
        ) {
          if (
            this.$refs.overviewgrid.$refs.overviewgrid.getColumns()[i].field ==
            "status"
          ) {
            this.$refs.overviewgrid.$refs.overviewgrid.getColumns()[
              i
            ].visible = false;
          }
        }
      } else if (args.requestType === "save") {
        for (
          var i = 0;
          i < this.$refs.overviewgrid.$refs.overviewgrid.getColumns().length;
          i++
        ) {
          if (
            this.$refs.overviewgrid.$refs.overviewgrid.getColumns()[i].field ==
            "status"
          ) {
            this.$refs.overviewgrid.$refs.overviewgrid.getColumns()[
              i
            ].visible = true;
          }
        }
      } else if (args.requestType === "save" && args.action === "edit") {
        this.updateIntervention({
          ...args.data,
          collaboratorId: args.data.collaborator.id,
        });
      }
    },
    async deleteButtonClicked(args) {
      this.$refs.overviewgrid.$refs.overviewgrid.deleteRecord(args.rowData.id);
      this.$refs.overviewgrid.$refs.overviewgrid.closeEdit();
      this.$refs.overviewgrid.$refs.overviewgrid.refresh();
      await this.deleteInterventions({
        interventionIds: [args.rowData.id],
      });
    },
    setCollaborators(collaboratorIds) {
      this.collaboratorIds = collaboratorIds;
    },
    onFilterTextBoxChanged(search) {
      this.$refs.overviewgrid.onFilterTextBoxChanged(search);
    },
    clickAction() {
      this.$router.push({
        name: "new-intervention",
        params: {
          routeOrigine: "my-interventions",
        },
      });
    },
    clickActionGroup(val) {
      this.selectedRows.forEach((row) => {
        let dataUpdate = {
          ...row,
          status: val,
        };
        this.updateIntervention({
          intervention: dataUpdate,
          interventionCustomFields: null,
        }).then((res) => {
          this.$refs.overviewgrid.$refs.overviewgrid.setRowData(
            dataUpdate.id,
            dataUpdate
          );
        });
      });
    },
    setSelectParamerterAction(args) {
      if (["csv", "xls", "print"].includes(args.item.type)) {
        this.$refs.overviewgrid.exportData(args.item.type);
      } else {
        this.$refs.overviewgrid.$refs.overviewgrid.openColumnChooser();
      }
    },
    getCountDataByStatus(status) {
      if (status == "all") {
        return this.dataOrigine.length;
      } else {
        let statusInfos = this.statusList.find((elem) => elem.value == status);
        return this.dataOrigine.filter((elem) =>
          statusInfos.query
            ? statusInfos.query.includes(elem.status)
            : elem.status === status
        ).length;
      }
    },
    getSumDataByStatus(status) {
      let sum = 0;
      if (status == "all") {
        this.dataOrigine?.forEach((obj) => {
          sum += obj["duration"];
        });
      } else {
        let statusInfos = this.statusList.find((elem) => elem.value == status);
        this.dataOrigine
          .filter((elem) =>
            statusInfos.query
              ? statusInfos.query.includes(elem.status)
              : elem.status === status
          )
          .forEach((obj) => {
            sum += obj["duration"];
          });
      }
      return this.formatNumber(sum);
    },
    setSelectedRows(val) {
      this.selectedRows = val;
    },
    onRowClicked(event) {
      this.$refs["sidebarInterventionContent"].getInterventionDetails(
        event.rowData.id,
        1
      );
      this.isOpenSidebarIntervention = true;
      this.$refs["sidebarIntervention"].toggle();
    },
    updateSidebarData(data) {
      this.$refs["sidebarIntervention"].data = data;
    },
    refreshRow(val) {
      this.$refs.overviewgrid.$refs.overviewgrid.setRowData(val.id, val);
    },
    refreshGrid(res) {
      this.$refs.overviewgrid.$refs.overviewgrid.refresh();
    },
    closeSidebar() {
      this.isOpen = false;
      this.$refs.sidebarIntervention.hide();
    },
  },
};
</script>
