<template lang="pug">
div.h-100
    .h-100(v-if='isLoadingIntervention || isUpdatingIntervention')
      .text-center.flex-center.h-100
        .loading-bg-inner
          .loader
            .outer
            .middle
            .inner
        .mt-5
          br
          br
          br
          | Chargement des détails de l'intervention...
    .h-100(v-else)
      .sidebar-header.pt-1.px-2
        div
          h3 Détail de l'intervention
        feather-icon.close-icon.cursor-pointer(icon='XIcon' size='24' @click.stop='closeSidebar')
      .sidebar-header.pb-1.px-2
        .d-flex
          Status(:statusId="intervention.status" nature="intervention" :key="intervention.id+'_'+intervention.status")
          span {{ intervention.date ? "En date du : " + dayjs(intervention.date).format("DD/MM/YYYY") : "" }}
        .end
      vs-divider.m-0
      .sidebar-action.py-05.px-2
        .d-flex.align-items-center.justify-content-between.w-100
          .d-flex
            b-button-group
              b-button.btn-icon.d-flex(v-if="intervention.status == 2" variant='primary' @click="editIntervention(intervention.id)" :style="'border-right:1px solid #ffffff !important;'")
                feather-icon(icon='EyeIcon') 
              b-button.btn-icon.d-flex(v-else style='border-left:1px solid #ffffff !important;' variant='primary' @click="editIntervention(intervention.id)")
                feather-icon(icon='EditIcon')
                span(style='margin-left:3px') Modifier
          .d-flex.align-items-center.justify-end(v-if="intervention.status == 0 || intervention.status == 1")
            b-button.btn-icon.d-flex(variant='outline-danger' @click="deleteInterventionLocal(intervention.id)")
              feather-icon(icon='TrashIcon') 
      vs-divider.m-0
      .sidebar-content.with-tabs
        div(v-if='intervention.companyId')
          .d-flex.justify-content-between
            h5.mb-0.d-flex.align-items-center.mb-0.text-primary.cursor-pointer(@mouseover="showCompanyEdit=true" @mouseleave="showCompanyEdit=false" style="flex: 1;")
              span.d-flex.align-items-center(style='min-width: fit-content; margin-right: -5px')
                feather-icon(icon='ChevronRightIcon') 
                | {{ intervention.companyDetails ? intervention.companyDetails.name : '' }}
                feather-icon.ml-50(@click="editCompany(intervention.companyId, 'client')" v-show="showCompanyEdit" icon='ExternalLinkIcon')
            .d-flex
              div(style='width: 10px; height:20px; background-color: #eef1f6; clip-path: polygon(100% 100%, 100% 0, 0 50%);')
              div(style="background:#eef1f6 !important; height: 20px; min-width: fit-content;")
                div.px-50
                  small Client
          div(v-if="intervention.companyDetails && intervention.companyDetails.addresses.length")
            span(v-show='intervention.companyDetails.addresses[0].address') {{ intervention.companyDetails.addresses[0].address }}
              br
            span(v-show='intervention.companyDetails.addresses[0].addressComplement') {{ intervention.companyDetails.addresses[0].addressComplement }}
              br
            span(v-show='intervention.companyDetails.addresses[0].city || intervention.companyDetails.addresses[0].zipCode') {{ intervention.companyDetails.addresses[0].zipCode }} {{ intervention.companyDetails.addresses[0].city }}
              br
            span(v-show='intervention.companyDetails.addresses[0].country') {{ intervention.companyDetails.addresses[0].country }}
          ul.listTab.mt-1
            li(v-if='intervention.companyDetails && intervention.companyDetails.email')
              span Email
              span {{ intervention.companyDetails.email }}
            li(v-if='intervention.companyDetails && intervention.companyDetails.phoneNumber')
              span Téléphone
              span {{ intervention.companyDetails.phoneNumber }}
          vs-divider
        
        div(v-if='intervention.affairId')
          .d-flex.justify-content-between.mb-1
              h5.mb-0.d-flex.mb-0.text-primary.cursor-pointer(@mouseover="showAffairEdit=true" @mouseleave="showAffairEdit=false" style="flex: 1;")
                span(style='min-width: fit-content; margin-right: -5px')
                  feather-icon(icon='ChevronRightIcon') 
                  | {{ intervention.affairDetails ? intervention.affairDetails.name : '' }}
                  feather-icon.ml-50(@click="editAffair(intervention.affairId)" v-show="showAffairEdit" icon='ExternalLinkIcon')
              .d-flex
                div(style='width: 10px; height:20px; background-color: #eef1f6; clip-path: polygon(100% 100%, 100% 0, 0 50%);')
                div(style="background:#eef1f6 !important; height: 20px; min-width: fit-content;")
                  div.px-50
                    small Affaire liée

        div(v-if='intervention.affairId')
          .d-flex
            h4.my-2 Détails
            vs-divider.ml-2
          .d-flex.justify-content-between.mb-1
              h5.mb-0.d-flex.mb-0.text-primary.cursor-pointer(style="flex: 1;")
                span(style='min-width: fit-content; margin-right: -5px')
                  feather-icon(icon='ChevronRightIcon') 
                  | {{ intervention.duration ? intervention.duration + ' heure(s)' : ''}}
              .d-flex
                div(style='width: 10px; height:20px; background-color: #eef1f6; clip-path: polygon(100% 100%, 100% 0, 0 50%);')
                div(style="background:#eef1f6 !important; height: 20px; min-width: fit-content;")
                  div.px-50
                    small Durée

          .d-flex.justify-content-between.mb-1(v-if="customTemplateName")
              h5.mb-0.d-flex.mb-0.text-primary.cursor-pointer(style="flex: 1;")
                span.text-truncate(style='min-width: fit-content; margin-right: -5px')
                  feather-icon(icon='ChevronRightIcon') 
                  | {{ customTemplateName }}
              .d-flex
                div(style='width: 10px; height:20px; background-color: #eef1f6; clip-path: polygon(100% 100%, 100% 0, 0 50%);')
                div(style="background:#eef1f6 !important; height: 20px; min-width: fit-content;")
                  div.px-50
                    small Formulaire      
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { mapGetters, mapActions } from "vuex";

import dayjs from "dayjs";

import Status from "@/components/global/status/Status";

export default {
  data() {
    return {
      dayjs: dayjs,
      showCompanyEdit: false,
      showAffairEdit: false,
    };
  },
  computed: {
    ...mapGetters([
      "isUpdatingIntervention",
      "isLoadingIntervention",
      "isDeletingIntervention",
      "companiesList",
      "affairsList",
      "interventionsList",
      "customFieldTemplatesList",
    ]),
    intervention: {
      get() {
        return this.$store.getters.intervention;
      },
      set(value) {
        return this.$store.commit("SET_INTERVENTION", value);
      },
    },
    customTemplateName() {
      const template = this.customFieldTemplatesList.find(
        (template) => template.id === this.intervention.customFieldTemplateId
      );
      return template ? template.label : null;
    },
  },
  methods: {
    ...mapActions(["getInterventionById", "deleteInterventions"]),
    async editIntervention(id) {
      this.closeSidebar();
      this.$router.push({
        name: "edit-intervention",
        params: {
          id: id,
          title: "Editer une intervention",
          tips: "Editer une intervention",
          routeOrigine: this.$route.path,
        },
      });
    },
    async showAffair(id) {
      this.closeSidebar();
      this.$router.push({
        name: "affair",
        params: {
          id: id,
          title: "Affaire : " + this.intervention.affairName,
          tips: "Affaire : " + this.intervention.affairName,
          routeOrigine: "affairs",
        },
      });
    },
    async editCompany(id) {
      this.closeSidebar();
      this.$router.push({
        name: "edit-company",
        params: {
          id: id,
          title: "Edit : " + this.intervention.companyName,
          tips: "Editer l'entreprise : " + this.intervention.companyName,
          //routeOrigine: "companies",
        },
      });
    },
    async editAffair(id) {
      this.closeSidebar();
      this.$router.push({
        name: "edit-affair",
        params: {
          id: id,
          title: "Edit : " + this.intervention.affairName,
          tips: "Editer l'affaire : " + this.intervention.affairName,
          //routeOrigine: "affairs",
        },
      });
    },
    async getInterventionDetails(id) {
      if (id != "") {
        this.getInterventionById({
          interventionId: id,
        }).then((res) => {
          this.intervention = {
            ...res,
            date: [res.date],
            companyDetails: this.companiesList.find(
              (elem) => elem.id == res.companyId
            ),
            affairDetails: this.affairsList.find(
              (elem) => elem.id == res.affairId
            ),
          };
        });
      }
    },
    toggleDeleteModal() {
      this.$refs["delete-modal"].toggle("#toggle-btn");
    },
    deleteInterventionLocal(id) {
      this.$bvModal
        .msgBoxConfirm("Cette action est définitive et irréversible.", {
          title: "Êtes-vous sûr de vouloir supprimer l'intervention ?",
          size: "sm",
          okVariant: "danger",
          okTitle: "Supprimer",
          cancelTitle: "Annuler",
          cancelVariant: "outline-primary",
          hideHeaderClose: true,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.deleteInterventions({
              interventionIds: [id],
              origine: this.$route.name,
            }).then(() => {
              this.closeSidebar();
              this.$emit("refreshGrid");
            });
          }
        });
    },
    closeSidebar() {
      this.$emit("closeSidebar");
    },
  },
  components: {
    VuePerfectScrollbar,
    Status,
  },
};
</script>

<style lang="scss" scoped>
.sidebar-action {
  flex-direction: column;
}

.cancel-modal-buttons {
  display: flex;
  justify-content: center;
}

.dropdown {
  position: relative;
  display: inline-block;
  font-size: 15px;
  cursor: pointer;

  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    border-radius: 5px;

    .dropdown-content-item {
      padding: 10px 10px 10px 10px;
      cursor: pointer;
      font-size: 14px;

      &:hover {
        background-color: #eeeeee;
      }
    }
  }
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdownbutton-redirect {
  background-color: #0000;
  border-color: #0000;
  color: #5c636a;
}
</style>
